const MERKIT = [
  {
    numero: 1,
    materiaali: "Muovi, Polyeteenitereftalaattia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "PET"
  },
  {
    numero: 2,
    materiaali: "Muovi, Korkeatiheyspolyeteenia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "HDPE"
  },
  {
    numero: 3,
    materiaali: "Muovi, Polyvinyylikloridia",
    lajittelu:
      "EI saa laittaa muovin kierrätykseen. Lajittele energia jätteeseen.",
    lyhenne: "PVC"
  },
  {
    numero: 4,
    materiaali: "Muovi, Matalatiheyspolyeteenia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "LDPE"
  },
  {
    numero: 5,
    materiaali: "Muovi, Polypropeenia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "PP"
  },
  {
    numero: 6,
    materiaali: "Muovi, Polystyreenia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "PS"
  },
  {
    numero: 7,
    materiaali: "Muovi, Yhdistelmämuovia",
    lajittelu: "Lajittele puhtaana muovipakkauskeräykseen.",
    lyhenne: "O"
  },
  {
    numero: 20,
    materiaali: "Paperi, kartonki tai pahvi",
    lajittelu: "Aaltopahvia, lajittele kartonkipakkauskeräykseen.",
    lyhenne: "PAP"
  },
  {
    numero: 21,
    materiaali: "Paperi, kartonki tai pahvi",
    lajittelu:
      "Muuta kartonkia tai pahvia, lajittele kartonkipakkauskeräykseen.",
    lyhenne: "PAP"
  },
  {
    numero: 22,
    materiaali: "Paperi, kartonki ja pahvi",
    lajittelu: "Paperia, lajittele kartonkipakkauskeräykseen.",
    lyhenne: "PAP"
  },
  {
    numero: 40,
    materiaali: "Metalli, teräs",
    lajittelu: "Lajittele metallipakkauskeräykseen.",
    lyhenne: "FE"
  },
  {
    numero: 41,
    materiaali: "Metalli, alumiini",
    lajittelu: "Lajittele metallipakkauskeräykseen.",
    lyhenne: "ALU"
  },
  {
    numero: 51,
    materiaali: "Puuaines",
    lajittelu: "Lajittele puujätteenkeräykseen",
    lyhenne: "FOR"
  },
  {
    numero: 60,
    materiaali: "Tekstiili, Puuvillaa",
    lajittelu:
      "Uudelleenkäyttöön kelpaamattomana sekä kuivana, lajittelu poistotekstiilin keräykseen. Muuten energiajätteeseen.",
    lyhenne: "TEX"
  },
  {
    numero: 61,
    materiaali: "Tekstiili, Juuttia",
    lajittelu:
      "Uudelleenkäyttöön kelpaamattomana sekä kuivana, lajittelu poistotekstiilin keräykseen. Muuten energiajätteeseen.",
    lyhenne: "TEX"
  },
  {
    numero: 70,
    materiaali: "Lasia, väritöntä",
    lajittelu:
      "Poista korkit sekä kannet, huuhtele kylmällä vedellä, ja lajittele lasinkeräykseen.",
    lyhenne: "GL"
  },
  {
    numero: 71,
    materiaali: "Lasia, vihreää",
    lajittelu:
      "Poista korkit sekä kannet, huuhtele kylmällä vedellä, ja lajittele lasinkeräykseen.",
    lyhenne: "GL"
  },
  {
    numero: 72,
    materiaali: "Lasia, ruskeaa",
    lajittelu:
      "Poista korkit sekä kannet, huuhtele kylmällä vedellä, ja lajittele lasinkeräykseen.",
    lyhenne: "GL"
  },
  {
    numero: 80,
    materiaali:
      "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/erinäiset metallit",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 81,
    materiaali: "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/muovi",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 82,
    materiaali: "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/alumiini",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 83,
    materiaali: "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/läkkipelti",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 84,
    materiaali:
      "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/muovi/alumiini",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 85,
    materiaali:
      "Yhdistelmämateriaalit, Paperi, kartonki ja pahvi/muovi/alumiini/läkkipelti",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 90,
    materiaali: "Yhdistelmämateriaalit, Muovi/alumiini",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 91,
    materiaali: "Yhdistelmämateriaalit, Muovi/läkkipelti",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 92,
    materiaali: "Yhdistelmämateriaalit, Muovi/erinäiset metallit",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 95,
    materiaali: "Yhdistelmämateriaalit, Lasi/muovi",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 96,
    materiaali: "Yhdistelmämateriaalit, Lasi/alumiini",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 97,
    materiaali: "Yhdistelmämateriaalit, Lasi/läkkipelti",
    lajittelu: "Lajittele energiajätteeseen"
  },
  {
    numero: 98,
    materiaali: "Yhdistelmämateriaalit, Lasi/erinäiset metallit",
    lajittelu: "Lajittele energiajätteeseen"
  }
];
module.exports = MERKIT;
