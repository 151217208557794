<template>
  <article id="first_main" v-if="!jatka">
    <section id="first_container">
      <p class="first_p" v-if="opas_1 && !opas_2">Tervetuloa</p>
      <p class="first_p" v-if="opas_1 && !opas_2">
        Tämä ohjeistus näytetään vain ensimmäisellä vierailullasi
      </p>
      <button
        class="first_buttons"
        v-on:click="opas_2 = true"
        v-if="opas_1 && !opas_2"
      >
        Jatka
      </button>
      <p class="first_p" v-if="opas_2 && !opas_3">
        Kierrätyskolmion
        <img
          :src="'./img/icons/kolmio.svg'"
          alt="kierrätyskolmio"
          id="kolmio"
        /><br />
        keskellä oleva numero kertoo, mistä materiaaleista pakkaus koostuu ja
        mihin keräysastiaan pakkaus lajittellaan.
      </p>
      <button
        class="first_buttons"
        v-on:click="opas_3 = true"
        v-if="opas_2 && !opas_3"
      >
        Jatka
      </button>
      <p class="first_p" v-if="opas_3 && !jatka">
        Muista että pakkausten tulee olla tyhjiä, puhtaita ja kuivia.
      </p>
      <p class="first_p" v-if="opas_3 && !jatka">
        Kiitos kun lajittelet jätteesi!
      </p>
      <button class="first_buttons" v-on:click="jatkuu" v-if="opas_3 && !jatka">
        Valmis
      </button>
    </section>
  </article>
  <recycle v-if="jatka" />
</template>

<script>
import recycle from "./recycle.vue";

export default {
  components: {
    recycle
  },
  data: function() {
    return {
      opas_1: true,
      opas_2: false,
      opas_3: false,
      jatka: false
    };
  },
  mounted() {
    if (localStorage.getItem("jatka")) {
      this.jatka = true;
    } else return;
  },
  methods: {
    jatkuu() {
      this.jatka = true;
      localStorage.setItem("jatka", "true");
    }
  }
};
</script>
<style lang="scss" scoped>
#first_main {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#first_container {
  display: flex;
  flex-direction: column;
  border: 0.1em solid black;
  border-radius: 0.5em;
  animation: ilmesty 0.5s forwards ease-in;
  max-width: 75vw;
  background-color: rgba(2, 97, 104, 0.87);
  margin: 0 auto;
  padding: 1.5em;
}
.first_p {
  animation: ilmesty 0.5s forwards ease-in;
  max-width: inherit;
  max-height: inherit;
  font-size: 1.25em;
  padding: 0.2em;
  display: inline-block;
  background-color: rgba(0, 48, 51, 0.5);
  border-radius: 0.25em;
  border: 0.1em solid black;
  margin: 0 auto 0.2em auto;
}
#kolmio {
  all: unset;
  vertical-align: middle;
  width: 1em;
  height: 0.8em;
}
.first_buttons {
  all: unset;
  cursor: pointer;
  border: 0.1em solid black;
  border-radius: 0.25em;
  background-color: rgb(0, 55, 59);
  padding: 0.25em;
  transition: transform 0.05s ease-in-out, background-color 0.05s ease-in-out;
}
.first_buttons:focus {
  background-color: rgb(0, 48, 51);
  transform: scale(0.9);
}
</style>
