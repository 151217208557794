<template>
  <section class="recycle">
    <h1 id="headline">
      <img
        :src="'./img/icons/kolmio.svg'"
        alt="kierrätyskolmio"
        id="kolmio"
      />Kierrätyskolmio
    </h1>
    <article>
      <div id="haku_kontti">
        <input
          class="haku input"
          v-model="haku"
          v-on:click="ehdota = !ehdota"
          v-on:keyup.enter="hae"
          type="number"
          placeholder="kierrätyskolmion numero"
        />
        <button id="ehdota_close" v-if="ehdota" v-on:click="ehdota = false">
          X
        </button>
        <button id="ehdota_close_CLOSED" v-if="!ehdota">x</button>
        <div id="ehdotus_kontti_CLOSED" v-if="!ehdota"></div>
        <div id="ehdotus_kontti" v-if="ehdota">
          <ul id="ehdotus_lista" v-for="(objekti, n) in merkit" v-bind:key="n">
            <li id="ehdotus" v-on:click="haku = merkit[n].numero">
              {{ merkit[n].numero }}
            </li>
          </ul>
        </div>
        <button class="haku button" v-on:click="hae">Etsi</button>
      </div>
      <div id="tulokset_kontti">
        <ul id="tulokset" v-for="(objekti, n) in tulos" v-bind:key="n">
          <li id="avain">{{ n }}</li>
          <li id="tulos">{{ objekti }}</li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      merkit: require("../targets/marks"),
      haku: null,
      tulos: [],
      ehdota: false
    };
  },
  methods: {
    hae() {
      this.ehdota = false;
      if (this.haku > 0) {
        var Y = this.haku;
        var objekti = this.merkit.find(function(merkki) {
          if (merkki.numero == Y) return true;
        });
        if (objekti != undefined) {
          this.tulos = objekti;
        } else if (objekti === undefined) {
          this.virhe("haku_kontti");
          var eiKolmio = [
            { virhe: "Syötä kierrätyskolmion keskellä oleva numero" }
          ];
          this.tulos = eiKolmio[0];
        }
      } else {
        this.virhe("haku_kontti");
        var virhe = [{ virhe: "Syötä kierrätyskolmion keskellä oleva numero" }];
        this.tulos = virhe[0];
      }
    },
    virhe(x) {
      document
        .getElementById(x)
        .animate(
          [
            { transform: "rotate(0deg)" },
            { transform: "rotate(3deg)" },
            { transform: "rotate(0deg)" },
            { transform: "rotate(-3deg)" },
            { transform: "rotate(0deg)" }
          ],
          { duration: 250 }
        );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.recycle {
  display: flex;
  flex-direction: column;
  animation: ilmesty 1s ease-in forwards;
  justify-content: space-evenly;
  margin: 0 auto;
}
#kolmio {
  height: 1em;
  vertical-align: middle;
  margin: 0.1em 0.5em 0 0;
  animation: kolmio 10s infinite ease-in-out;
}
#headline {
  font-size: 1.4em;
  width: inherit;
  display: flex;
  flex-direction: row;
  letter-spacing: 0.18em;
  background-color: rgba(40, 52, 56, 20);
  animation: leiju 10s ease-in-out infinite;
  border: 0.1em solid black;
  margin: 0.75em auto;
  padding: 0.45em 0.3em;
  border-radius: 0.2em;
}
#haku_kontti {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.haku {
  outline: none;
  box-shadow: -0.1em 0.1em 0.25em black;
  font-size: 1em;
  text-align: center;
  border: 0.1em solid black;
}
.haku.input {
  padding: 0.35em 0;
  color: rgb(229, 242, 250);
  border-radius: 0.5em 0.5em 0 0;
  transition: background-color 0.15s ease-in-out;
}
.haku.input:hover {
  background-color: rgb(19, 117, 124);
}
.haku.button {
  cursor: pointer;
  padding: 0.25em 0;
  color: rgb(229, 242, 250);
  background-color: rgb(4, 94, 100);
  border-radius: 0 0 0.5em 0.5em;
  transition: background-color 0.15s ease-in-out;
}
.haku.button:hover {
  background-color: rgb(0, 52, 56);
}
#ehdotus_kontti {
  width: 99%;
  margin: 0 auto;
  border: 0.1em solid black;
  background-color: rgb(1, 78, 83);
  animation: ehdotusAvaudu 0.5s forwards;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
#ehdotus_kontti_CLOSED {
  height: 5em;
  background-color: rgb(1, 78, 83);
  animation: ehdotusSulkeudu 0.5s forwards;
  overflow: none;
}
#ehdota_close {
  outline: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  background-color: rgb(0, 52, 56);
  color: rgb(229, 242, 250);
  animation: ehdotusAvaudu 0.4s forwards;
  border: 0.1em solid black;
  padding: 0.1em;
}
#ehdota_close_CLOSED {
  outline: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  animation: ehdotusSulkeudu 0.6s forwards;
  background-color: rgb(0, 52, 56);
  color: rgb(229, 242, 250);
  border: 0.1em solid black;
  padding: 0.1em;
}
#ehdotus {
  cursor: pointer;
}
#tulokset_kontti {
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(20, 52, 56, 20);
  border: 0.1em solid black;
  border-radius: 0.5em;
}
#tulokset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.1em solid black;
  border-radius: 0.35em;
  margin: 0.35em 0.35em;
  transition: transform 0.3s ease-in-out;
}
#tulokset:hover {
  transform: scale(1.02);
}
#avain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: capitalize;
  width: 100%;
  max-width: inherit;
  background-color: rgba(0, 98, 107, 50);
  padding: 0.2em 0;
  border-radius: 0.35em 0.35em 0 0;
  border-bottom: 0.05em solid black;
  margin: 0 auto;
}
#tulos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: inherit;
  background-color: rgba(2, 83, 117, 50);
  padding: 0.35em 0;
  border-radius: 0 0 0.35em 0.35em;
  border-top: 0.05em solid black;
  margin: 0 auto;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
@media screen and (max-width: 600px) {
  #haku_kontti {
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  #headline {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 320px) {
  #headline {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 290px) {
  #headline {
    font-size: 1.1em;
  }
}
@media screen and (max-width: 270px) {
  #headline {
    font-size: 1em;
  }
}
@media screen and (max-width: 260px) {
  #headline {
    font-size: 0.9em;
  }
}
</style>
